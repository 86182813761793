export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED";
export const DELIVERY_STATUS_9 = "ADDRESS_NOT_SUPPORTED";
export const DELIVERY_STATUS_10 = "ACCIDENT";
export const DELIVERY_STATUS_11 = "CANCELED";

export class Delivery {
  accidentAt = null;
  canceledAt = null;
  bookId = "";
  corpUser = null;
  deliveryCompletedDate = null;
  deliveryPlace = false;
  receiverName = null;
  releasedAt = null;
  rider = null;
  senderName = null;
  status = DELIVERY_STATUS_0;

  constructor(delivery) {
    this.bookId = delivery.bookId;

    switch (Number(delivery.status)) {
      case 0:
        this.status = DELIVERY_STATUS_0;
        break;
      case 1:
        this.status = DELIVERY_STATUS_1;
        break;
      case 2:
        this.status = DELIVERY_STATUS_2;
        break;
      case 3:
        this.status = DELIVERY_STATUS_3;
        break;
      case 4:
        this.status = DELIVERY_STATUS_4;
        break;
      case 5:
        this.status = DELIVERY_STATUS_5;
        break;
      case 6:
        this.status = DELIVERY_STATUS_6;
        break;
      case 7:
        this.status = DELIVERY_STATUS_7;
        break;
      case 8:
        this.status = DELIVERY_STATUS_8;
        break;
      case 10:
        this.status = DELIVERY_STATUS_10;
        break;
      case 11:
        this.status = DELIVERY_STATUS_11;
        break;
      default:
        throw new Error("잘못된 배송상태값입니다.");
    }

    this.accidentAt = delivery.accidentAt;
    this.canceledAt = delivery.canceledAt;
    this.corpUser = delivery.corpUser;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.deliveryPlace = delivery.deliveryPlace;
    this.receiverName = delivery.receiverName;
    this.releasedAt = delivery.releasedAt;
    this.rider = delivery.rider;
    this.senderName = delivery.senderName;
  }

  get displaySenderName() {
    return this.corpUser ? this.corpUser.title : this.senderName;
  };

  get errorStatus() {
    if (this.accidentAt || this.status === DELIVERY_STATUS_10) {
      return "사고"
    } else if (this.canceledAt || this.status === DELIVERY_STATUS_11) {
      return "취소"
    } else if (this.deliveryPlace) {
      return "수령희망장소요청완료"
    } else if (this.status === DELIVERY_STATUS_5) {
      return "배송완료"
    } else if (this.status === DELIVERY_STATUS_6) {
      return "반송완료"
    } else if (this.status === DELIVERY_STATUS_7) {
      return "분실완료"
    } else {
      return null;
    }
  };
}
