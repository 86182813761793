import React from "react";
import styled from "@emotion/styled";

const Self = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  border-top: 1px solid #333;
  font-size: 16px;
  color: #999;
  line-height: 1;

  ${({ active }) => active && `
    color: #fff;
  `}

  ${({ noBorder }) => noBorder && `
    border-top: none;
  `}
`

const Icon = styled.div`
  width: 14px;
  height: 14px;
  border: solid 1px #999999;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconInner = styled.div`
  ${({ active }) => active && `
    width: 8px;
    height: 8px;
    background-color: #75ff6f;
    border-radius: 50%;
  `}
`

const Radio = ({ children, active, noBorder, ...restProps }) => (
  <Self active={active} noBorder={noBorder} {...restProps}>
    <Icon>
      <IconInner active={active} />
    </Icon>
    {children}
  </Self>
);

export default Radio;